
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from "@/mixin/apiEndpoint";
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Institute Wise Training Statistic Report',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Multiselect,
    ElNotification,
    Swal,


  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      courses: [] as any,
      association: [] as any,
      tranches: [] as any,
      trancheInfo: [] as any,
      optionsTranche: [] as any,
      entity_id: '' as any,
      institute_id: '' as any,
      tranche_id: [] as any,
      course_info_id: '' as any,
      courseWiseBreakDown: [] as any,
      totalbatch: 0 as any,
      totalenrollment: 0 as any,
      totalassesment: 0 as any,
      totalcertification: 0 as any,
      totaljob_placement: 0 as any,
      totalfemale_enrollment: 0 as any,
      totalfemale_assesment: 0 as any,
      totalfemale_certification: 0 as any,
      totalfemale_job_placement: 0 as any,
      totaljob_placement_3m: 0 as any,

      componentKey: 0,
      showBtn: false,
      showCourseSummary: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getAssociation();
    await this.getTranches();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {


    async getCourseList() {
      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }

      await ApiService.get(
        'course/list?entity_id=' + entity_id + '&tranche=' + this.tranche_id
      )
        .then((response) => {
          this.courses = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {

      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.trancheInfo = response.data.data;
          this.trancheInfo.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    showBtnStatus() {
      this.showBtn = true;
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('entity', this.entity_id);
      formData.set('tranche', this.tranche_id);
      formData.set('course', this.course_info_id);
      await ApiService.post('report/course_wise_breakdown_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {


      let formData = new FormData();

      formData.set('entity', this.entity_id);
      formData.set('tranche', this.tranche_id);
      formData.set('course', this.course_info_id);
      let data = `${this.VUE_APP_API_URL}/api/report/course_wise_breakdown_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('entity', this.entity_id);
      formData.set('tranche', this.tranche_id);
      formData.set('course', this.course_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/course_wise_breakdown_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Course_Wise_BreakDown.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async courseWiseBreakDownReport() {
      this.load = true;
      await ApiService.get(
        'report/course-wise-break-down?entity=' +
        this.entity_id +
        '&tranche=' +
        this.tranche_id +
        '&course=' +
        this.course_info_id
      )
        .then((response) => {
          this.courseWiseBreakDown = response.data.data.coursebreakdown;
          this.totalbatch = response.data.data.totalbatch;
          this.totalenrollment = response.data.data.totalenrollment;
          this.totalassesment = response.data.data.totalassesment;
          this.totalcertification = response.data.data.totalcertification;
          this.totaljob_placement = response.data.data.totaljob_placement;
          this.totaljob_placement_3m = response.data.data.totaljob_placement_3m;
          this.totalfemale_enrollment =
            response.data.data.totalfemale_enrollment;
          this.totalfemale_assesment = response.data.data.totalfemale_assesment;
          this.totalfemale_certification =
            response.data.data.totalfemale_certification;
          this.totalfemale_job_placement =
            response.data.data.totalfemale_job_placement;
          this.componentKey += 1;
          this.showCourseSummary = true;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
